import offers from "../../api/offers";
let _ = require('lodash');

export const state = {
    isLoading: false,
    offers: []
};

export const mutations = {
    START_LOADING(state) {
        state.isLoading = true;
    },

    STOP_LOADING(state) {
        state.isLoading = false;
    },

    SET_OFFERS(state, offers) {
        state.offers = offers;
    }
};

export const getters = {
    isLoading: (state) => {
        return state.isLoading;
    },

    offers: (state) => {
        return state.offers;
    }
};

export const actions = {
    fetch({ commit, getters }) {
        commit('START_LOADING');
        commit('SET_OFFERS', []);
        offers.getAll().then(r => {
            commit('STOP_LOADING');
            commit('SET_OFFERS', r.data);
        }).catch(e => {
            commit('STOP_LOADING');
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
