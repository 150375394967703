import accounts from "../../api/accounts";
let _ = require('lodash');

export const state = {
    isLoading: false,
    numberOfAccounts: null,
    accounts: [],
    selectedAccountId: null
};

export const mutations = {
    START_LOADING(state) {
        state.isLoading = true;
    },

    STOP_LOADING(state) {
        state.isLoading = false;
    },

    SET_ACCOUNTS(state, accounts) {
        state.accounts = accounts;
    },

    SELECT_ACCOUNT_ID(state, accountId) {
        state.selectedAccountId = accountId;
    },

    SET_NUMBER_OF_ACCOUNTS(state, numberOfAccounts) {
        state.numberOfAccounts = numberOfAccounts;
    }
};

export const getters = {
    isLoading: (state) => {
        return state.isLoading;
    },

    accounts: (state) => {
        return state.accounts;
    },

    selectedAccountId: (state) => {
        return state.selectedAccountId;
    },

    selectedAccount: (state) => {
        return _.find(state.accounts, { id: state.selectedAccountId });
    },

    numberOfAccounts: (state) => {
        return state.numberOfAccounts;
    }
};

export const actions = {
    fetch({ commit, getters }) {
        commit('START_LOADING');
        commit('SET_ACCOUNTS', []);
        accounts.getAll().then(r => {
            commit('STOP_LOADING');
            commit('SET_ACCOUNTS', r.data);
            commit('SET_NUMBER_OF_ACCOUNTS', r.data.length);
            if (r.data.length) {
                if (!_.map(r.data, 'id').includes(getters.selectedAccountId) || getters.selectedAccountId == null) {
                    commit('SELECT_ACCOUNT_ID', _.first(r.data).id);
                }
            } else {
                commit('SELECT_ACCOUNT_ID', null);
            }
        }).catch(e => {
            commit('STOP_LOADING');
        });
    },

    selectAccountId({commit}, accountId) {
        commit('SELECT_ACCOUNT_ID', accountId);
    },

    setNumberOfAccounts({commit}, numberOfAccounts) {
        commit('SET_NUMBER_OF_ACCOUNTS', numberOfAccounts);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
