import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import vuetify from "./plugins/vuetify";
import "./plugins";
import axios from 'axios';

// Determine active profile
let profiles = {
  local: "http://maiaportal.ddev.site",
  testing: "https://api-mai.openimagination.co.uk",
  live: "https://api.maiaportal.com"
};
let activeProfile = 'local';
if (window.location.host == 'uat-mai.openimagination.co.uk') {
  activeProfile = 'testing';
}
if (window.location.host == 'maiaportal.com') {
  activeProfile = 'live';
}
if (window.location.host == 'www.maiaportal.com') {
  activeProfile = 'live';
}

window.activeProfile = activeProfile;

window.API_BASE = profiles[window.activeProfile] + '/api/investors';
window.API_BASE_ADMIN = profiles[window.activeProfile] + '/api/admin';
window.API_COMMON_BASE = profiles[window.activeProfile] + '/api/common';
window.IMAGE_STORAGE_BASE = profiles[window.activeProfile] + '/storage';
window.API_TOKEN_URL = profiles[window.activeProfile] + '/api/token';

import octaviaKit from "@/plugins/octavia.kit";
import "@/assets/scss/enhanceable/index.scss";

// import VueApexCharts from 'vue-apexcharts';
// mock
import "./fake-db/index.js";

// Vue.component('apexchart', VueApexCharts);


Vue.use(octaviaKit);
Vue.config.productionTip = false;
// if (process.env.NODE_ENV === "development") {
//   makeServer();
// }

// Axios Interceptor
axios.interceptors.request.use(
  config => {
    const token = store.getters['auth/apiToken'];
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  error => {
    Promise.reject(error)
  });


new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount("#app");
