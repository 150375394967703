import Vuex from 'vuex'
import Vue from 'vue'
import largeSidebar from './modules/largeSidebar'
import compactSidebar from './modules/compactSidebar'
import themeConfig from './modules/themeConfig'
import auth from './modules/auth'
import accounts from './modules/accounts'
import verticalSidebar from './modules/verticalSidebar'
import offers from "./modules/offers";

// Load Vuex
Vue.use(Vuex)

// Vuex Storage
import VuexPersist from 'vuex-persist';

const vuexStorage = new VuexPersist({
  key: 'maia-frontend-auth',
  storage: window.localStorage,
  modules: ['auth', 'accounts']
})

// Create store
export default new Vuex.Store({
  plugins: [vuexStorage.plugin],
  modules: {
    largeSidebar,
    compactSidebar,
    themeConfig,
    verticalSidebar,
    auth,
    accounts,
    offers
  },
})
