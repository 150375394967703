import axios from 'axios';

export default {
    getAll() {
        return axios.get(window.API_BASE + '/offers');
    },

    get(id) {
        return axios.get(window.API_BASE + '/offers/' + id);
    },

    acceptOffer(id) {
        return axios.post(window.API_BASE + '/offers/' + id + '/accept');
    }
}