import axios from 'axios';

export default {
    getAll() {
        return axios.get(window.API_BASE + '/accounts');
    },
  get(accountId) {
    return axios.get(window.API_BASE + '/accounts/' + accountId);
  }
}
